import { css } from "goober";

export const InventoryContainer = () => css`
  & {
    display: flex;
    gap: var(--sp-2);
  }

  .category-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: var(--sp-0_5);
  }
  .item-container {
    --radii: var(--br);
    --width: var(--sp-6);
    position: relative;
    width: var(--width);
    height: auto;
    aspect-ratio: 1;
    padding: 10%;
    background: var(--wep-category-color, var(--shade6));
    border-radius: var(--radii);
  }
  .item {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border-radius: var(--radii);
    filter: invert(1);
  }
  .tier-roman {
    position: absolute;
    left: 50%;
    top: 0%;
    color: color-mix(in hsl, var(--wep-category-color), white 30%);
    font-weight: 700;
    translate: -50% -45%;
    font-size: 0.75rem;
    text-shadow:
      var(--shade10) 1px 0px,
      var(--shade10) 1px 1px,
      var(--shade10) 0px 1px,
      var(--shade10) -1px 1px,
      var(--shade10) -1px 0px,
      var(--shade10) -1px -1px,
      var(--shade10) 0px -1px,
      var(--shade10) 1px -1px,
      var(--shade10) 0 0 3px,
      var(--shade10) 0 0 3px,
      var(--shade10) 0 0 3px;
  }
`;
