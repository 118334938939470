import React, { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import HeroPerformance from "@/game-deadlock/components/HeroPerformance.jsx";
import MatchHistoryHeader from "@/game-deadlock/components/MatchHistoryHeader.jsx";
import DeadlockMatchTile from "@/game-deadlock/components/MatchTile.jsx";
import {
  SearchParamsDefault,
  SearchParamsEnum,
} from "@/game-deadlock/constants/enums.mjs";
import getMatchlist from "@/game-deadlock/utils/get-matchlist.mjs";
import { MatchList, MatchTile } from "@/shared/Profile.jsx";
import { ProfileColumn, ProfileMatchlist } from "@/shared/Profile.style.jsx";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { useQuery, useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function ProfileOverview() {
  const {
    parameters: [steamId],
  } = useRoute();
  const [gameMode] = useQuery(SearchParamsEnum.Mode);
  const [hero] = useQuery(SearchParamsEnum.Hero);
  const {
    deadlock: { matchlist, matchlistMeta },
  } = useSnapshot(readState);
  const matchlistByPlayer = useMemo(
    () => getMatchlist(matchlist[steamId]),
    [steamId, matchlist],
  );
  const matchlistItems = useEvalState(matchlistByPlayer);
  const matchlistRx = useMemo(() => {
    return matchlistItems?.slice?.().filter((i) => {
      const params = [gameMode, hero];
      if (
        params.every((i) => !i) ||
        params.every((i) => i === SearchParamsDefault)
      )
        return true;
      const matchlistItem = filterErrState(matchlistMeta[steamId]?.[i.matchId]);
      if (!matchlistItem) return true;
      const against = [];
      if (hero && hero !== SearchParamsDefault)
        against.push(String(matchlistItem.player.hero_id) === hero);
      if (gameMode && gameMode !== SearchParamsDefault)
        against.push(String(matchlistItem.game_mode) === gameMode);
      if (!against.length) return true;
      return against.every(Boolean);
    });
  }, [gameMode, hero, matchlistMeta, matchlistItems, steamId]);
  return (
    <>
      <ProfileColumn className="sidebar">
        <HeroPerformance steamId={steamId} />
      </ProfileColumn>
      <ProfileColumn className="main">
        <ProfileMatchlist>
          <MatchHistoryHeader />
          <MatchList matchList={matchlistByPlayer}>
            {matchlistRx?.map?.((i) => {
              const $matchlistMetaItem = matchlistMeta[steamId]?.[i.matchId];
              const matchlistMetaItem = filterErrState($matchlistMetaItem);
              return (
                <MatchTile
                  key={i.matchId}
                  id={i.matchId}
                  match={$matchlistMetaItem}
                  height={170}
                >
                  {!!matchlistMetaItem && (
                    <DeadlockMatchTile
                      steamId={steamId}
                      matchlistMetaItem={matchlistMetaItem}
                    />
                  )}
                </MatchTile>
              );
            })}
          </MatchList>
        </ProfileMatchlist>
      </ProfileColumn>
    </>
  );
}
