import React, { memo, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import type { ElementOf } from "ts-essentials";

import { formatDuration } from "@/app/util.mjs";
import PlayerInventory from "@/game-deadlock/components/MatchPlayerInventory.jsx";
import { MatchTileTabsEnum } from "@/game-deadlock/constants/enums.mjs";
import getMatch from "@/game-deadlock/fetches/match.mjs";
import type { Match } from "@/game-deadlock/models/match.mjs";
import type { MatchlistMeta } from "@/game-deadlock/models/matchlist.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import MatchTileExpand from "@/shared/MatchTileExpand.jsx";
import globals from "@/util/global-whitelist.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";

const Tabs = {
  [MatchTileTabsEnum.Scoreboard]: () =>
    import("@/game-deadlock/components/MatchTileScoreboard.jsx"),
  [MatchTileTabsEnum.Statistics]: () =>
    import("@/game-deadlock/components/MatchTileStatistics.jsx"),
  [MatchTileTabsEnum.Hits]: () =>
    import("@/game-deadlock/components/MatchTileHits.jsx"),
};
const Labels = [
  {
    name: ["tft:matchtabs.scoreboard", "Scoreboard"] as Translation,
    key: MatchTileTabsEnum.Scoreboard,
  },
  {
    name: ["common:navigation.statistics", "Statistics"] as Translation,
    key: MatchTileTabsEnum.Statistics,
  },
  {
    name: ["val:stats.hits", "Hits"] as Translation,
    key: MatchTileTabsEnum.Hits,
  },
];

export default memo(function MatchListRow({
  steamId,
  matchlistMetaItem,
}: {
  steamId: string;
  matchlistMetaItem: MatchlistMeta;
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const [isFetching, setIsFetching] = useState(false);
  const player = useMemo(
    () => matchlistMetaItem.player,
    [matchlistMetaItem.player],
  );
  const {
    kills = 0,
    assists = 0,
    deaths = 0,
    stats = [],
    items = [],
  } = useMemo(() => player ?? ({} as ElementOf<Match["players"]>), [player]);
  const matchMins = matchlistMetaItem.duration_s
    ? matchlistMetaItem.duration_s / 60
    : 1;
  const eogPlayerStats = stats.at(-1);
  const playerDamageDealt = eogPlayerStats?.player_damage || 0;
  const dmgPerMin = playerDamageDealt / matchMins;
  const playerSouls = eogPlayerStats.net_worth || 0;
  const soulsPerMin = playerSouls / matchMins;
  const playerDenies = eogPlayerStats.denies || 0;
  const deniesPerMin = playerDenies / matchMins;
  const isWin = matchlistMetaItem.winning_team === player.team;
  const kda = kills + assists / (deaths || 1);
  const handleOnExpand = useCallback(async () => {
    // If data retrieval is slower than chunk loading, this allows the internal async component to handle loading states
    // Eg. DataTable and Cards have loading states
    setIsFetching(true);
    await getMatch({ matchId: String(matchlistMetaItem.match_id) });
    setIsFetching(false);
  }, [matchlistMetaItem.match_id]);
  return (
    <MatchTileExpand
      isWin={isWin}
      image={{
        src: Assets.getHeroPortrait(player?.hero_id),
        alt: steamId,
        style: { translate: "0% 10%" },
      }}
      titles={[
        {
          text: isWin
            ? t("common:victory", "Victory")
            : t("common:defeat", "Defeat"),
          bold: true,
          color: isWin ? "var(--turq)" : "var(--red)",
        },
        {
          text: t("common:minutes", "{{minutes}} mins", {
            minutes: formatDuration(
              matchlistMetaItem.duration_s * 1000,
              "m:ss",
            ),
          }),
        },
      ]}
      tabDefault={MatchTileTabsEnum.Scoreboard}
      tabsToElement={Tabs}
      tabsLabel={Labels}
      tabArgs={{
        matchId: matchlistMetaItem.match_id,
        steamId,
        isFetching,
      }}
      urlWeb={`${globals.location.origin}/deadlock/match/${matchlistMetaItem.match_id}/${steamId}`}
      urlCanonical={`/deadlock/match/${steamId}/${matchlistMetaItem.match_id}`}
      matchDate={new Date(matchlistMetaItem.start_time * 1000)}
      aside={
        Array.isArray(matchlistMetaItem.heroes) && (
          <div className={cssPlayerGrid(matchlistMetaItem.heroes.length)}>
            {matchlistMetaItem.heroes.map((heroId) => {
              return (
                <div key={String(heroId)} className="flex col gap-1">
                  <img
                    width="16px"
                    height="16px"
                    src={Assets.getHeroPortrait(heroId)}
                  />
                </div>
              );
            })}
          </div>
        )
      }
      onExpand={handleOnExpand}
    >
      <div style={{ display: "grid", gap: "var(--sp-4)" }}>
        <div className={`flex row gap-6 ${cssZone()}`}>
          <div>
            <div className="type-subtitle--bold">
              {t("common:stats.kda", "{{ kda, number }} KDA", {
                kda,
              })}
            </div>
            <div className="type-caption shade1">
              {t("lol:displayKDA", "{{kills}} / {{deaths}} / {{assists}}", {
                kills,
                deaths,
                assists,
              })}
            </div>
          </div>
          <div data-tip={t("common:stats.damagePerMin", "Damage / Min.")}>
            <div className="type-subtitle--bold">
              {t("common:stats.damagePerMinValue", "{{dpm}} DPM", {
                dpm: dmgPerMin.toLocaleString(language, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerDamageDealt).toLocaleString(language)}
            </div>
          </div>
          <div data-tip={t("deadlock:stats.soulsPerMin", "Souls / Min.")}>
            <div className="type-subtitle--bold">
              {t("deadlock:stats.spm", "{{spm}} SPM", {
                spm: soulsPerMin.toLocaleString(language, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerSouls).toLocaleString(language)}
            </div>
          </div>
          <div>
            <div className="type-subtitle--bold">
              {t("deadlock:stats.valueDeniesPerMin", "{{dpm}} Denies / Min.", {
                dpm: deniesPerMin.toLocaleString(language, {
                  minimumFractionDigits: 1,
                  maximumFractionDigits: 1,
                }),
              })}
            </div>
            <div className="type-caption shade1">
              {sanitizeNumber(playerDenies).toLocaleString(language)}
            </div>
          </div>
        </div>
        <PlayerInventory items={items} />
      </div>
    </MatchTileExpand>
  );
});

const cssPlayerGrid = (length: number) => css`
  display: grid;
  gap: var(--sp-px) var(--sp-1);
  grid-template-columns: repeat(
    ${Math.ceil(sanitizeNumber(length / 6) || 1)},
    1fr
  );
  & .type-caption {
    max-width: 7ch;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const cssZone = () => css`
  & .item-grid {
    display: grid;
    gap: var(--sp-1);
    grid-template-columns: repeat(5, 1fr);
  }
  & .item-grid > img {
    width: var(--sp-8);
    height: var(--sp-8);
  }
`;
