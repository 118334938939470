import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import type { Matchlist } from "@/game-deadlock/models/matchlist.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { heroWinrateColor } from "@/game-deadlock/utils/hero-winrate-color.mjs";
import { kdaColor } from "@/game-deadlock/utils/kda-color.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import ListItemsTruncated from "@/shared/ListItemsTruncated.jsx";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import debounce from "@/util/debounce.mjs";
import { filterErrState, useEvalState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { useIsLoaded } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function HeroPerformance({ steamId }: { steamId: string }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const isLoaded = useIsLoaded();
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSetLoader = useCallback(debounce(setIsLoading, 2e3), []);
  const {
    deadlock: { matchlist: _matchlist, matchlistMeta, heroes: $heroes },
  } = useSnapshot(readState);
  const heroes = useEvalState($heroes);
  const matchlist = useEvalState<Matchlist>(_matchlist[steamId]);
  const stats = useMemo(() => {
    const matches = matchlist
      ?.map?.((id) => {
        const matchlistMetaItem = filterErrState(matchlistMeta[steamId]?.[id]);
        const playerStats = matchlistMetaItem?.player;
        if (!playerStats) return null;
        const win = matchlistMetaItem.winning_team === playerStats.team ? 1 : 0;
        return {
          ...playerStats,
          win,
        };
      })
      .filter(Boolean);

    if (!matches?.length) return [];

    const aggStats = matches.reduce((acc, match) => {
      const { hero_id } = match;
      if (!acc[hero_id]) {
        acc[hero_id] = {
          hero_id: hero_id,
          kills: 0,
          deaths: 0,
          assists: 0,
          wins: 0,
          games: 0,
        };
      }
      acc[hero_id].kills += match.kills;
      acc[hero_id].deaths += match.deaths;
      acc[hero_id].assists += match.assists;
      acc[hero_id].wins += match.win;
      acc[hero_id].games++;
      return acc;
    }, {});

    return Object.values(aggStats) as {
      hero_id: string;
      kills: number;
      deaths: number;
      assists: number;
      wins: number;
      games: number;
    }[];
  }, [matchlist, matchlistMeta, steamId]);

  useEffect(() => {
    if (!isLoaded) {
      setIsLoading(true);
    } else {
      debounceSetLoader(false);
    }
  }, [debounceSetLoader, isLoaded]);

  if (isLoading && !stats.length) {
    return (
      <div className="relative">
        <Card loading style={{ height: 360 }} />
        <div className="absolute-center">
          <BlitzLogoLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      <ListItemsTruncated
        dontTruncate
        headerControls={
          <span className="type-caption shade2">
            {t("common:stat.lastCountGames", "Last {{count}} Games", {
              count: stats.reduce((acc, curr) => acc + curr.games, 0),
            })}
          </span>
        }
        title={t("common:stats.recentPerformance", "Recent Performance")}
        list={stats}
        onShowMore={() => {}}
        itemLeftContent={(stat) => {
          const { games, kills, deaths, assists } = stat;
          const [avgKills, avgDeaths, avgAssists] = [
            kills,
            deaths,
            assists,
          ].map((i) => sanitizeNumber(i / games));
          const hero = heroes?.[stat.hero_id];
          const avgKDA = calcKDA(avgKills, avgDeaths, avgAssists);
          return (
            <>
              <img
                src={Assets.getHeroPortrait(stat.hero_id)}
                width="36px"
                height="36px"
              />
              <div>
                <p className="type-callout--bold">{hero?.heroName}</p>
                <p
                  className="type-caption--semi"
                  style={{
                    color: kdaColor(avgKDA),
                  }}
                >
                  {t("lol:matchHistory.kda", "{{kda}} KDA", {
                    kda: avgKDA.toLocaleString(language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }),
                  })}
                </p>
              </div>
            </>
          );
        }}
        itemRightContent={({ wins, games }) => {
          const losses = games - wins;
          return (
            <div style={{ display: "grid" }}>
              <span className="type-caption--bold">
                {t(
                  "lol:matchHistory.winsAndLossesWithHypen",
                  "{{wins}}W-{{losses}}L",
                  {
                    wins,
                    losses,
                  },
                )}
              </span>
              <span
                className="type-caption--semi"
                style={{ color: heroWinrateColor(wins / games) }}
              >
                {t("common:winRatePercent", "{{winRate, percent}} WR", {
                  winRate: wins / games,
                  formatParams: {
                    maximumFractionDigits: 0,
                  },
                })}
              </span>
            </div>
          );
        }}
        itemLinkFormat={(stat) =>
          `/deadlock/heroes/${heroes?.[stat.hero_id]?.heroName}`
        }
      />
      {isLoading && (
        <>
          <div className="absolute-center">
            <BlitzLogoLoading />
          </div>
          <Card
            loading
            classNameOuter={`${cssOpacity()} h-full w-full absolute left-0 top-0`}
            style={{ height: "100%" }} // Override internal card height
          />
        </>
      )}
    </div>
  );
});

const cssOpacity = () => css`
  opacity: 50%;
`;
