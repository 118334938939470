import React, { memo, useMemo } from "react";
import type { ElementOf } from "ts-essentials";

import { InventoryContainer } from "@/game-deadlock/components/MatchPlayerInventory.style.jsx";
import type { AbilitiesItems } from "@/game-deadlock/models/abilitiesItems.mjs";
import type { Match } from "@/game-deadlock/models/match.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { getItemColor } from "@/game-deadlock/utils/get-item-color.mjs";
import { useItemsAbilities } from "@/game-deadlock/utils/use-items-abilties.mjs";

const TIER_ROMAN = {
  EModTier_1: "I",
  EModTier_2: "II",
  EModTier_3: "III",
  EModTier_4: "IV",
};

function PlayerInventory({ items = [] }: { items: Items }) {
  const { loading: itemsLoading, dict: itemsStaticData } = useItemsAbilities();

  const categores = useMemo(() => {
    const playerItems = items.filter((purchase) => {
      const itemStaticData = itemsStaticData[purchase.item_id];
      const isAnItem = itemStaticData?.m_eAbilityType === "EAbilityType_Item";
      return isAnItem && !purchase.sold_time_s;
    });
    return createItemGroups(playerItems, itemsStaticData);
  }, [items, itemsStaticData]);

  if (itemsLoading) {
    return (
      <div className={InventoryContainer()}>
        {Array.from({ length: 16 }).map((_, i) => (
          <div key={i} style={{ height: 24, width: 24 }} />
        ))}
      </div>
    );
  }

  return (
    <div className={InventoryContainer()}>
      {categores.map((category, i) => {
        return (
          <div key={i} className="category-group">
            {category.map((purchase, i) => {
              if (!purchase)
                return (
                  <div key={i} className="item-container">
                    <div className="item" />
                  </div>
                );
              const item = itemsStaticData[purchase.item_id];
              const tierRoman = TIER_ROMAN[item?.m_iItemTier];
              return (
                <div
                  key={i}
                  className="item-container"
                  style={{
                    "--wep-category-color": getItemColor(item),
                  }}
                  data-item-id={purchase.item_id}
                >
                  <img
                    src={Assets.getItemAbilityImage(item)}
                    width="24"
                    height="24"
                    className="item"
                  />
                  {!!tierRoman && (
                    <span className="tier-roman">{tierRoman}</span>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

export default memo(PlayerInventory);

// Maybe move this to a separate file
function createItemGroups(
  items: Items,
  itemsStaticData: AbilitiesItems,
): FixedLengthArray<Items, 4> {
  const weaponItems = [];
  const vitalityItems = [];
  const spiritItems = [];
  const flexItems = [];
  const payload: FixedLengthArray<Items, 4> = [
    weaponItems,
    vitalityItems,
    spiritItems,
    flexItems,
  ];

  if (!itemsStaticData) return payload;

  for (const item of items) {
    const itemStaticData = itemsStaticData[item.item_id];
    if (!itemStaticData) continue;

    const category = itemStaticData.m_eItemSlotType;
    if (category === "EItemSlotType_WeaponMod" && weaponItems.length < 4) {
      weaponItems.push(item);
    } else if (category === "EItemSlotType_Armor" && vitalityItems.length < 4) {
      vitalityItems.push(item);
    } else if (category === "EItemSlotType_Tech" && spiritItems.length < 4) {
      spiritItems.push(item);
    } else {
      flexItems.push(item);
    }
  }

  return payload;
}

type Items = ElementOf<Match["players"]>["items"];
